import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { v4 } from 'uuid';

import Layout from '../components/layout';
import Container from '../components/Container';
import SEO from '../components/seo';
import {
  BodyText,
  Hero,
  HeroHeading,
} from '../components/Global';
import { Grid, GridItem } from '../components/Grid';
import theme from '../styles/theme';
import MediaBreakDown from '../styles/MediaBreakDown';
import SignUpModal from '../components/SignUpModal';
import SimplifyTeamWork from '../components/SimplifyTeamWork';


const ExWrapper = styled.div`
  width: 100%;
  max-width: 1165px;
  margin: auto;
  .card {
    display: flex;
    min-height: 300px;
    padding: 43px;
    border: 1px solid ${theme.borderColor.grayBorder};
    border-radius: 10px;
    box-shadow: 0 4px 4px ${theme.boxShadow.light};
    .gatsby-image-wrapper {
      width: 35px;
    }
    .card-text {
      position: relative;
      margin-left: 20px;
      margin-top: 8px;

      h3 {
        font-size: 18px;
        line-height: 21.6px;
        color: ${theme.textColor.blackText};
      }

      .learn-more {
        position: absolute;
        bottom: 0;
      }
    }
    &:hover {
      content: '';
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      .learn-more {
        color: ${theme.hover.hoverBlue};
      }
    }
  }
  ${MediaBreakDown.xl`
    .card {
      padding: 43px 20px;
    }
  `}
  ${MediaBreakDown.lg`
    .card {
      padding: 43px 30px;
    }
  `}
  ${MediaBreakDown.md`
    .card {
      max-width: 400px;
      margin: auto;
    }
  `}
`;


const ExtensionsPage = ({ location, data }) => (
  <Layout location={location}>
    <SEO
      title="Extensions | Streamline Your Workflow with Workast's Powerful Extensions"
      description="Customize your business workflow using extensions that make the most sense for you and your business. Learn about our extensions here."
    />
    <Container>
      <Hero>
        <HeroHeading mb="21px">Extensions</HeroHeading>
        <BodyText mb="35px">Extend the functionality of Workast with app extensions and customize each space to suit your needs. Everything you need - nothing you don’t.</BodyText>
        <SignUpModal buttonText="Get Started" />
      </Hero>
      <ExWrapper>
        <Grid gridGap="2rem" minCol="270px">
          {data.allContentfulExtensionPage.edges.map(({ node }) => (
            <GridItem key={v4()}>
              <Link to={`/extensions/${node.slug}/`}>
                <div className="card">
                  <div>
                    <GatsbyImage image={getImage(node.icon)} />
                  </div>
                  <div className="card-text">
                    <h3>{node.name}</h3>
                    <BodyText color={theme.body.light}>
                      {node.shortDescription.shortDescription}
                    </BodyText>
                    <div className="learn-more">
                      Learn more
                    </div>
                  </div>
                </div>
              </Link>
            </GridItem>
          ))}
        </Grid>
      </ExWrapper>

      <SimplifyTeamWork />
    </Container>
  </Layout>
);

ExtensionsPage.propTypes = {
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    allContentfulExtensionPage: PropTypes.shape({
      edges: PropTypes.shape({
        map: PropTypes.shape({
          // eslint-disable-next-line react/forbid-prop-types
          icon: PropTypes.object,
          name: PropTypes.string,
          shortDescription: PropTypes.shape({
            shortDescription: PropTypes.string
          }).isRequired,
          slug: PropTypes.string,
        })
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ExtensionsPage;

export const pageQuery = graphql`
  query ExtensionsPageQuery{
    allContentfulExtensionPage(sort: {order: ASC, fields: order}) {
      edges {
        node {
          name
          title
          slug
          shortDescription {
            shortDescription
          }
          icon {
            gatsbyImageData(width:35)
          }
        }
      }
    }
  }
`;
