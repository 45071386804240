import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: ${props => props.alignItems};
  background: ${props => props.bgColor};

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      ${props => `minmax(${props.minCol}, ${props.maxCol})`}
    );
    grid-gap: ${props => props.gridGap};
    align-items: ${props => props.alignItems};
    justify-content: center;
  }
`;
export const GridItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-bottom: 1rem;
  min-width: 230px;
  background: white;
  height: 100%;
  align-items: center;

  @supports (display: grid) {
    margin-right: 0;
    margin-bottom: 0;
    min-width: unset;
  }
`;

export const Grid = ({
  children, gridGap, minCol, maxCol, alignItems, bgColor
}) => (
  <GridWrapper
    gridGap={gridGap}
    minCol={minCol}
    maxCol={maxCol}
    alignItems={alignItems}
    bgColor={bgColor}
  >
    {children}
  </GridWrapper>
);

Grid.propTypes = {
  gridGap: PropTypes.string,
  minCol: PropTypes.string,
  maxCol: PropTypes.string,
  alignItems: PropTypes.string,
  bgColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
};

Grid.defaultProps = {
  gridGap: '0px',
  minCol: '230px',
  maxCol: '1fr',
  alignItems: 'flex-start',
  bgColor: '#ffffff',
};

export const GridItem = ({ children }) => (
  <GridItemWrapper>{children}</GridItemWrapper>
);
GridItem.propTypes = {
  children: PropTypes.element.isRequired,
};
